import React from "react"
import { Container } from "../styles/baseStyles"
import Navbar from "./navbar"
import Footer from "./footer"

export default function Layout({ children, location }) {
  return (
    <>
      <Navbar location={location} />
      <Container shift={location.pathname !== "/"}>
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  )
}
