import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Link from "./link"
import { FaInstagram } from "react-icons/fa"

export default function Footer() {
  const { contact } = useStaticQuery(graphql`
    {
      contact: contentfulContact {
        mail
        instagram
      }
    }
  `)
  return (
    <FooterContainer>
      <Link to="/">
        <Logo>
          <StaticImage
            placeholder="none"
            src="../images/apta_full.png"
            alt="footer logo"
          />
        </Logo>
      </Link>
      <Mail>
        <p>Have Questions? We are happy to help.</p>
        <h2>{contact.mail}</h2>
      </Mail>
      <Links>
        <Link to="/portfolio">Our Works</Link>
        <Link to="/team">Team</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/contact">Contact</Link>
      </Links>
      <Social as={Link} to={contact.instagram}>
        <FaInstagram />
      </Social>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f1f3f4;
  margin-top: auto;
  padding: 3.5rem calc((100vw - var(--container-width)) / 3);

  @media screen and (max-width: 1170px) {
    padding: 2rem 5vw;
  }

  @media screen and (max-width: 880px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 580px) {
    padding: 3rem 5vw;
  }
`
const Logo = styled.div`
  img {
    height: 10rem;
    width: 20rem;
    @media screen and (max-width: 1170px) {
      height: 8rem;
      width: 16rem;
    }
    @media screen and (max-width: 880px) {
      height: 10rem;
      width: 20rem;
    }
    @media screen and (max-width: 580px) {
      height: 11rem;
      width: 20rem;
    }
  }
`
const Mail = styled.div`
  margin-left: -10rem;

  p {
    font-size: 1.4rem;
    margin-bottom: 0.7rem;
  }
  h2 {
    font-size: 2rem;

    &::after {
      content: "";
      display: block;
      height: 0.1rem;
      width: 100%;
      background: var(--text-mid);
      margin-top: 0.6rem;
    }
  }
  @media screen and (max-width: 1170px) {
    margin-left: -5rem;
    h2 {
      font-size: 1.75rem;
    }
    p {
      margin-bottom: 0.4rem;
    }
  }

  @media screen and (max-width: 880px) {
    order: 1;
    flex: 0 0 100%;
    margin: 3rem 0 0 2rem;
    h2 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 580px) {
    flex: 0 0 80%;
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--text-mid);
    display: block;
    font-size: 1.8rem;
    &:not(:last-child) {
      margin-right: 6rem;
    }
  }

  @media screen and (max-width: 1070px) {
    a {
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }
  }

  @media screen and (max-width: 880px) {
    margin-left: auto;
    margin-right: 4rem;
  }

  @media screen and (max-width: 580px) {
    flex: 0 0 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3vw;
    margin-top: 4rem;
    a {
      margin-right: 0 !important;
      font-size: 2.25rem;
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
  }
`

const Social = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 38px;
    width: 38px;
    fill: var(--text-mid);
    @media screen and (max-width: 1070px) {
      height: 28px;
      width: 28px;
    }
  }
  @media screen and (max-width: 580px) {
    margin-top: auto;
    order: 2;
    flex: 0 0 10%;
  }
`
