import { createGlobalStyle } from "styled-components"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary-color: #0060F1;
				--secondary-color: #0B1111;
        --text-dark: #000000;
        --text-mid: #262625;
        --text-light: #312f2f;
        --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
        --shadow-mid: 0 2px 5px rgba(0, 0, 0, 0.1);
        --shadow-light: 0 2px 5px rgba(0, 0, 0, 0.06);
        --shadow-button: 0 1rem 2rem 0 rgba(0,0,0,0,15);
        --container-width-narrow: 870px;
				--container-width: 1070px;
				--container-width-wide: 1170px;
        --breakpoint-tablet: 720px;
    }

    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html {
        font-size: 48%;
        box-sizing: border-box;
    }

    body {
        font-family: "Roboto", sans-serif;
        color: var(--text-mid);
        font-size: 1.6rem;
				overflow-x: hidden;
		}

		#___gatsby {
			overflow-x: hidden;
		}

    #gatsby-focus-wrapper {
        display: flex;
        flex-direction: column;
				min-height: 100vh;
    }

    p {
        color: var(--text-light);
        line-height: 1.5;

        @media screen and (max-width: 1024px) {
            line-height: 1.5;
            font-size: 1.5rem;
        }
    }

    a {
        color: var(--primary-color);
        text-decoration: none;
	}
	@media screen and (min-width: 1440px){
		:root {
			--container-width: 1270px;
		}
		html {
			font-size: 62%;
		}
	}
	@media screen and (max-width: 480px) {
		html {
			font-size: 40%;
	}
	}
`
