import styled, { css } from "styled-components"

export const Container = styled.div`
    max-width: var(--container-width);
    margin 7rem auto 0;
    width: 95%;
    ${props =>
      props.wide &&
      css`
        max-width: var(--container-width-wide);
      `}
    ${props =>
      props.narrow &&
      css`
        max-width: var(--container-width-narrow);
      `}
	${props =>
    props.shift &&
    css`
      max-width: 100vw;
      width: 100%;
      padding-left: calc((100vw - var(--container-width)) / 2.5);
      padding-right: calc((100vw - var(--container-width)) / 2.5);
    `}
`

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  font-size: 1.7rem;
  border-radius: 2.5rem;
  padding: 0.7rem 2rem;
  box-shadow: var(--shadow-button);
  transition: all 0.2s;
  font-weight: 700;

  @media screen and (max-width: 480px) {
    font-size: 2.1rem;
  }

  ${props =>
    props.primary &&
    css`
      background: var(--primary-color);
      color: #fff;
      border: 1px solid transparent;

      &:hover {
        background: #fff;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    `}

  ${props =>
    props.secondary &&
    css`
      background: var(--secondary-color);
      border: 1px solid var(--secondary-color);
      color: #fff;

      &:hover {
        background: #fff;
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
      }
    `}
	${props =>
    props.hollow &&
    css`
      background: #fff;
      border: 1px solid var(--secondary-color);
      color: var(--text-dark);
    `}
  ${props =>
    props.gradient &&
    css`
      background: linear-gradient(135deg, #00c7c5 0%, #28b463 100%);
      border: 1px solid transparent;
      color: #fff;
      padding: 1rem 2rem;
      &:hover {
        background: #fff;
        border: 1px solid var(--primary-color);
        color: #00c7c5;
      }
    `}
	${props =>
    props.mini &&
    css`
      font-size: 1.4rem;
      padding: 0.6rem 1.7rem;
      border-radius: 10px;
    `}
	${props =>
    props.loader &&
    css`
      ${ButtonWithState}
      &::before {
        background-image: url("/images/button-loader.svg");
        background-size: 40px 40px;
      }
    `}

	${props =>
    props.success &&
    css`
      ${ButtonWithState}
      &::before {
        background-size: 15px 20px;
        background-image: url("/images/check.svg");
      }
    `}

	${props =>
    props.error &&
    css`
      ${ButtonWithState}
      &::before {
        background-size: 14px 14px;
        background-image: url("/images/cancel.svg");
      }
    `}
`

const ButtonWithState = css`
  cursor: not-allowed;
  color: #fff;
  display: flex;
  align-items: center;
  &:hover {
    background: #000;
    color: #fff;
  }
  &::before {
    content: "";
    display: block;
    background-position: center;
    height: 18px;
    width: 18px;
    background-repeat: no-repeat;
    margin-right: 1.5rem;
  }
`

export const Title = styled.div`
  font-size: 4rem;
  font-weight: 700;
  color: var(--text-mid);
  @media screen and (max-width: 880px) {
    font-size: 3.4rem;
  }
`
