import React from "react"
import Layout from "./src/components/layout"
import { GlobalStyles } from "./src/styles/globalStyles"

export const wrapPageElement = ({ element, props }) => (
  <>
    <GlobalStyles />
    <Layout {...props}>{element}</Layout>
  </>
)
