import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Link from "./link"
import LogoSVG from "../images/apta_logo.svg"

export default function Navbar({ location }) {
  const { contact } = useStaticQuery(graphql`
    {
      contact: contentfulContact {
        mail
        phone
      }
    }
  `)
  const [isNavOpen, setNavOpen] = useState(false)
  const [windowState, setWindowState] = useState({ overflow: true, scrollY: 0 })
  const navRef = useRef({
    location: null,
  })

  useEffect(() => {
    if (!navRef.current.location) navRef.current.location = location
    else if (navRef.current.location !== location) {
      if (isNavOpen) setNavOpen(false)
      navRef.current.location = location
      setWindowState({ overflow: true, scrollY: 0 })
      document.body.style.overflowY = "unset"
    }
  }, [location, isNavOpen])

  return (
    <NavbarContainer open={isNavOpen} ref={navRef}>
      <Logo shift={location.pathname !== "/"}>
        <Link to="/">
          <LogoIcon />
        </Link>
      </Logo>
      <MenuToggle
        onClick={e => {
          let pageY = e.pageY - 60
          setNavOpen(prevState => !prevState)
          setWindowState(prevState => ({
            overflow: !prevState.overflow,
            scrollY: pageY,
          }))
          let windowOverflow = windowState.overflow ? "hidden" : "unset"
          let scrollY = windowState.overflow ? 0 : windowState.scrollY
          document.body.style.overflowY = windowOverflow
          window.scrollTo(0, scrollY)
        }}
        open={isNavOpen}
      >
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </MenuToggle>
      <Body show={isNavOpen} shift={location.pathname !== "/"}>
        <Menu>
          <Link to="/portfolio">Our Works</Link>
          <Link to="/team">Team</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/services">Areas Of Service</Link>
        </Menu>
        <Contact>
          <h2>Get In Touch</h2>
          <p>{contact.phone.join(", ")}</p>
          <p>{contact.mail}</p>
          <Link to="/contact">
            <h4>Contact Form &rarr;</h4>
          </Link>
        </Contact>
      </Body>
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
  position: absolute;
  top: 0rem;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, #000 50%);
  background-size: 200%;
  ${props =>
    props.open &&
    css`
      transition: background 0.3s;
      position: fixed;
      bottom: 0;
      width: 100vw;
      background-position-x: 100%;
      ${LogoIcon} {
        g {
          fill: #fff;
        }
      }
    `};
`

const Logo = styled.div`
  margin-left: calc((100vw - var(--container-width)) / 2);
  margin-top: 2rem;
  ${props =>
    props.shift &&
    css`
      margin-left: calc((100vw - var(--container-width)) / 2.5);
    `}
  @media screen and (max-width: 1170px) {
    margin-left: 5vw;
  }
  @media screen and (max-width: 980px) {
    ${props =>
      props.shift &&
      css`
        svg {
          g {
            fill: #000;
          }
        }
      `}
  }
`
const LogoIcon = styled(LogoSVG)`
  width: 13rem;
  height: 6rem;
  @media screen and (max-width: 980px) {
    width: 10rem;
    g {
      fill: #fff;
    }
  }
  @media screen and (max-width: 480px) {
    width: 10.5rem;
    height: 7rem;
    margin-top: 0.7rem;
  }
`

const MenuToggle = styled.div`
  z-index: 1;
  position: fixed;
  top: 2rem;
  right: calc((100vw - var(--container-width)) / 2.5);
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  transition: all 0.2s;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1170px) {
    right: 5vw;
  }

  @media screen and (max-width: 880px) {
    right: 7vw;
  }

  @media screen and (max-width: 480px) {
    top: 3.5rem;
    right: 5vw;
  }

  div {
    width: 53%;
  }

  span {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: #686666;
    border-radius: 25px;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }

  ${props =>
    props.open &&
    css`
      transform: rotate(45deg);
      transition: transform 0.2s;
      span {
        margin-bottom: 0 !important;
        height: 4px;
      }
      span:nth-child(1) {
        transform: rotate(90deg) translate(6%, -10%);
        transform-origin: 50%;
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        transform-origin: 50%;
        transform: translate(0%, -60%);
      }
      &:hover {
        transform: rotate(225deg);
      }
    `}
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: calc((100vw - var(--container-width)) / 2);
  visibility: hidden;
  height: 0;
  opacity: 0;
  color: #fff;
  @media screen and (max-width: 1170px) {
    margin-left: 6vw;
  }
  ${props =>
    props.shift &&
    css`
      margin-left: calc((100vw - var(--container-width)) / 2.5);
    `}
  ${props =>
    props.show &&
    css`
      transition: all 0.2s;
      transition-delay: 0.1s;
      visibility: visible;
      opacity: 1;
      height: 85%;
    `}
`
const lineUnder = css`
  &::after {
    content: "";
    display: block;
    background: #fff;
    width: 0;
    height: 0.2rem;
    opacity: 0.2;
    transition: all 0.5s;
  }
  &:hover {
    &::after {
      opacity: 0.8;
      width: 80%;
    }
  }
`
const Menu = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  a {
    &:first-child {
      margin-top: 4rem;
    }
    display: block;
    width: max-content;
    color: #fff;
    font-size: 4rem;
    margin-top: 1.2rem;
    font-weight: 300;
    ${lineUnder}
  }
`

const Contact = styled.div`
  margin-top: auto;
  h2 {
    margin-bottom: 1.5rem;
  }
  p {
    color: #fff;
    margin-top: 0.5rem;
    font-size: 1.7rem;
  }
  h4 {
    color: #fff;
    width: max-content;
    font-size: 1.7rem;
    margin-top: 1.5rem;
    cursor: pointer;
    ${lineUnder}
    &::after {
      margin-top: 0.3rem;
    }
  }
`
